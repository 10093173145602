<template>
  <div>
    <el-image
      class="banner"
      :src="require('@as/serve/brand/banner.png')"
    ></el-image>
    <div class="flow">
      <div class="item">
        <el-image
          class="flow_icon"
          :src="require('@as/serve/brand/icon_1.png')"
          alt=""
          srcset=""
        />
        <div>step 1</div>
        <div class="detail">通过管家咨询匹配您需要服务模块</div>
      </div>
      <div class="flow_line"></div>
      <div class="item">
        <el-image
          class="flow_icon"
          :src="require('@as/serve/brand/icon_2.png')"
          alt=""
          srcset=""
        />
        <div>step 2</div>
        <div class="detail">深度了解您所需的 服务诉求</div>
      </div>
      <div class="flow_line"></div>
      <div class="item">
        <el-image
          class="flow_icon"
          :src="require('@as/serve/brand/icon_3.png')"
          alt=""
          srcset=""
        />
        <div>step 3</div>
        <div class="detail">为您定制最吻合的 解决方案</div>
      </div>
      <div class="flow_line"></div>
      <div class="item">
        <el-image
          class="flow_icon"
          :src="require('@as/serve/brand/icon_4.png')"
          alt=""
          srcset=""
        />
        <div>step 4</div>
        <div class="detail">通过双方协助将该服务试用于您</div>
      </div>
    </div>
    <div class="card_container">
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_5.png')"
          ></el-image>
          <div class="name">品牌/公关/营销解决方案</div>
        </div>
        <div class="detail">
          <div class="info">
            服务于法律机构2B及2C客户的品牌公关构建，提供战略定位或大事件公关期策略及配套全案执行
          </div>
          <div class="emphasis">
            <div class="emphasis_title">战略定位</div>
            <div class="emphasis_detail">
              从市场、用户、媒体、客户等角度提供独立策略交付，帮助机构和个人进行资源对接
            </div>
          </div>
          <div class="emphasis">
            <div class="emphasis_title">品牌活动/行业活动</div>
            <div class="emphasis_detail">
              基于HUI律影响力及行业领先策划能力，为机构和团队提供线下行业峰会、品牌活动全案策划执行
            </div>
          </div>
          <div class="emphasis">
            <div class="emphasis_title">社会化品牌营销</div>
            <div class="emphasis_detail">
              HUI律整合营销全案服务，通过HUI律媒体及合作媒体，帮助品牌简历行业影响力和知名度
            </div>
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_6.png')"
          ></el-image>
          <div class="name">政企行研解决方案</div>
        </div>
        <div class="detail">
          <div class="info">
            知名咨询机构和研究机构团队，拥有丰富的经验，致力于为各家提供专业定制化咨询服务
          </div>
          <div class="emphasis">
            <div class="emphasis_title">股权投资研究</div>
            <div class="emphasis_detail">
              从市场、用户、媒体、客户等角度提供独立策略交付，帮助机构和个人进行资源对接
            </div>
          </div>
          <div class="emphasis">
            <div class="emphasis_title">开放式创新架构</div>
            <div class="emphasis_detail">
              基于HUI律影响力及行业领先策划能力，为机构和团队提供线下行业峰会、品牌活动全案策划执行
            </div>
          </div>
          <div class="emphasis">
            <div class="emphasis_title">产业战略研究</div>
            <div class="emphasis_detail">
              HUI律整合营销全案服务，通过HUI律媒体及合作媒体，帮助品牌简历行业影响力和知名度
            </div>
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_7.png')"
          ></el-image>
          <div class="name">企业管理解决方案</div>
        </div>
        <div class="detail">
          <div class="info">
            结合智能决策模型算法为机构和团队提供精准的组织诊断和人力资源管理策略建议
          </div>
          <div class="emphasis">
            <div class="emphasis_title">人力资源服务</div>
            <div class="emphasis_detail">
              从市场、用户、媒体、客户等角度提供独立策略交付，帮助机构和个人进行资源对接
            </div>
          </div>
          <div class="emphasis">
            <div class="emphasis_title">企业合规服务</div>
            <div class="emphasis_detail">
              基于HUI律影响力及行业领先策划能力，为机构和团队提供线下行业峰会、品牌活动全案策划执行
            </div>
          </div>
          <div class="emphasis">
            <div class="emphasis_title">知识产权保护</div>
            <div class="emphasis_detail">
              HUI律整合营销全案服务，通过HUI律媒体及合作媒体，帮助品牌简历行业影响力和知名度
            </div>
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_8.png')"
          ></el-image>
          <div class="name">股权激励体系搭建</div>
        </div>
        <div class="detail">
          <div class="info">
            帮助企业在成立初期，根据不同的业务发展阶段，制定合适的动态激励体系
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_30.png')"
          ></el-image>
          <div class="name">企业IPO咨询</div>
        </div>
        <div class="detail">
          <div class="info">
            针对不同上市地，提供符合当地上市要求的法律合规咨询及上市服务
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_10.png')"
          ></el-image>
          <div class="name">VIE架构搭建</div>
        </div>
        <div class="detail">
          <div class="info">
            配合公司发展进程，提供专业的VIE/红筹架构搭建咨询
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_11.png')"
          ></el-image>
          <div class="name">企业培训</div>
        </div>
        <div class="detail">
          <div class="info">
            帮助企业在成立初期，根据不同的业务发展阶段，制定合适的动态激励体系/红筹架构搭建咨询
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_9.png')"
          ></el-image>
          <div class="name">产品设计咨询</div>
        </div>
        <div class="detail">
          <div class="info">
            针对不同上市地，提供符合当地上市要求的法律合规咨询及上市服务
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
      <div class="card">
        <div class="title">
          <el-image
            class="icon"
            :src="require('@as/serve/brand/icon_12.png')"
          ></el-image>
          <div class="name">企业商标注册</div>
        </div>
        <div class="detail">
          <div class="info">
            配合公司发展进程，提供专业的VIE/红筹架构搭建咨询
          </div>
        </div>
        <div class="more_btn" @click="toDetail()">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toDetail() {
      this.$router.push({ name: 'brandDetail' })
    },
  },
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;

  height: 400px;
  display: block;
}
.flow {
  margin: 39px auto 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1200px;
  .item {
    width: 132px;
    font-size: 20px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #393939;
    display: flex;
    flex-direction: column;
    align-items: center;
    .flow_icon {
      margin-bottom: 19px;
      width: 60px;
      height: 60px;
      display: block;
    }
    .detail {
      margin-top: 24px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
  }
  .flow_line {
    margin: 0 21px;
    width: 190px;
    height: 1px;
    background: #a0a0a0;
    opacity: 0.4;
  }
}
.card_container {
  margin: 0 auto;
  padding-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1203px;

  .card {
    width: 383px;
    border: 1px solid #a0a0a0;
    box-sizing: border-box;
    &:hover {
      box-shadow: 0px 2px 5px 1px rgba(23, 23, 23, 0.13);
    }
    &:not(:nth-child(3n)) {
      margin-right: 27px;
    }
    &:nth-child(-n + 3) {
      margin-bottom: 26px;
    }
    &:nth-last-child(-n + 3) {
      margin-top: 53px;
    }
    .title {
      padding: 20px 26px;
      display: flex;
      align-items: center;
      background: #f4f6f8;
      border-bottom: 1px solid #a0a0a0;
      .icon {
        width: 40px;
        height: 40px;
      }
      .name {
        margin-left: 23px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #393939;
      }
    }
    .detail {
      padding: 30px 26px 0;
      .info {
        line-height: 24px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7e7e7e;
      }
      .emphasis {
        margin-top: 23px;
        .emphasis_title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #393939;
        }
        .emphasis_detail {
          padding-bottom: 18px;
          margin-top: 11px;

          line-height: 16px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e7e7e;
          line-height: 16px;
          border-bottom: 1px solid #bfbfbf;
        }
      }
    }
    .more_btn {
      margin: 21px auto 29px;
      width: 86px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #006cb5;
      text-align: center;
      background: #d1f0ff;
      border: 1px solid #d6d3d3;
      opacity: 0.5;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>